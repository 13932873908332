<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con">
                <div class="con_table">
                    <div class="con_top mb-10">
                        <h2><span>■</span> 기본정보</h2>
                    </div>

                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th>고객명 <span class="red">*</span></th>
                                <td class="left pl-20">
                                    <input v-model="name" type="text" class="w-200px" value="" disabled> <br>
                                </td>
                                <th>성별 <span class="red">*</span></th>
                                <td class="left pl-20">
                                    <select v-model="gender" class="w-60px">
                                        <option value="">성별</option>
                                        <option value="남자">남</option>
                                        <option value="여자">여</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>생년월일</th>
                                <td class="left pl-20">
                                    <crm-datepicker v-model="birth" :parentDate="birth" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                                </td>
                                <th>연령선택 <span class="red">*</span></th>
                                <td class="left pl-20">
                                    <select class="w-120px" v-model="ageRange">
                                        <option value="">연령선택</option>
                                        <option value="10대 이하">10대 이하</option>
                                        <option value="20대">20대</option>
                                        <option value="30대">30대</option>
                                        <option value="40대">40대</option>
                                        <option value="50대이상">50대이상</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>이메일</th>
                                <td colspan="3" class="left pl-20">
                                    <input v-model="email" type="text" class="w-200px" disabled>
                                </td>
                            </tr>
                            <tr>
                                <th>휴대폰 번호 <span class="red">*</span></th>
                                <td class="left pl-10">
                                    <select class="ml-10" v-model="phone1" id="phone1" name="phone1" disabled>
                                        <option value="010">010</option>
                                        <option value="011">011</option>
                                        <option value="016">016</option>
                                        <option value="017">017</option>
                                        <option value="018">018</option>
                                        <option value="019">019</option>
                                    </select> -
                                    <input type="text" v-model="phone2" style="width:100px;" id="phone2" name="phone2" value="" disabled> -
                                    <input type="text" v-model="phone3" style="width:100px;" id="phone3" name="phone3" value="" disabled>
                                </td>
                                <th>유입경로</th>
                                <td class="left pl-20">
                                    <select v-model="idxCrmMetaRoute" class="w-120px">
                                        <option value="-1">유입경로DB</option>
                                        <option v-for="(item, index) of routeList" :key="index" :value="item.idx">{{item.type}}</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="con_top mb-10">
                        <h2><span>■</span> 상담정보</h2>
                    </div>

                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr v-if="auth === 'MASTER' || auth === 'ADMIN'">
                                <th class="w-120px">상담센터 <span class="red">*</span></th>
                                <td class="left pl-20">
                                    <!-- <input type="text" value="본원센터" disabled> -->
                                    <select v-model="idxCrmCenter" @change="getStaffList()" class="w-120px">
                                        <!-- <option value="-1">센터선택</option> -->
                                        <option value="0">본원센터</option>
                                        <option v-for="(item, index) of centerList" :key="index" :value="item.idx">{{item.name}}</option>
                                    </select>
                                </td>
                                <!-- <th class="w-120px">상담사</th>
                                <td class="left pl-20 reset-br">
                                    <input type="text" class="w-200px" value="">
                                </td> -->
                                <th>사용가능회기</th>
                                <td class="left pl-20">
                                    <input type="text" v-model="consCount" class="w-60px" value=""> 회
                                </td>
                            </tr>
                            <tr v-if="auth === 'MASTER' || auth === 'ADMIN'">
                                <th>상담구분</th>
                                <td class="left pl-20">
                                    <select v-model="gubun" @change="getProductList()" class="w-120px">
                                        <option value="">구분</option>
                                        <option v-for="(item, index) of gubunList" :key="index" :value="item.type">{{item.type}}</option>
                                    </select>
                                    <select v-model="idxCrmMetaProduct" class="w-120px ml-10">
                                        <option value="-1">상품명</option>
                                        <option v-for="(item, index) of productList" :key="index" :value="item.idx">{{item.product}} ({{item.price}}원)</option>
                                    </select>
                                </td>
                                <th>기타권 금액</th>
                                <td class="left pl-20">
                                    <input type="text" v-model="etcPrice" class="w-60px"> 원
                                </td>
                            </tr>
                            <tr v-if="auth === 'MASTER' || auth === 'ADMIN'">
                                <th class="w-120px">상담유형</th>
                                <td class="left pl-20">
                                    <select v-model="idxCrmMetaType" class="w-200px">
                                        <option value="-1">상담유형선택</option>
                                        <option v-for="(item, index) of typeList" :key="index" :value="item.idx">{{item.type}}</option>
                                    </select>
                                </td>
                                <th>소속</th>
                                <td class="left">
                                    <div id="app" v-on:keyup.down="selectValue('down')" v-on:keyup.up="selectValue('up')">
                                        <div class="search">
                                            <input type="text" v-model="companyName" class="ml-05" style="width:200px;" id="company" name="company" @input="watchEvent($event, 'company')">
                                            <ul class="r" tabindex="0" v-bind:class="{ show: isAutoCompany }" v-on:mouseover="removeValue">
                                                <li tabindex="-1" v-for="(el, index) in filterCompanyList" v-on:click="changeValue(el.name, 'company', el.idx)" v-on:keyup.enter="selectValue('enter', el.name, el.idx)" :key="index">
                                                    <span>{{ el.name }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                                <!-- <th class="w-120px">상담주제</th>
                                <td class="left pl-20">
                                    <select v-model="idxCrmMetaSubject" class="w-120px">
                                        <option value="-1">상담주제선택</option>
                                        <option v-for="(item, index) of subjectList" :key="index" :value="item.idx">{{item.type}}</option>
                                    </select>
                                </td> -->
                            </tr>
                            <tr>
                                <th>상담사</th>
                                <td class="left">
                                    <div id="app" v-on:keyup.down="selectValue('down')" v-on:keyup.up="selectValue('up')">
                                        <div class="search">
                                            <!-- <input type="text" @click="checkCenter()" v-model="staffName" class="ml-05" style="width:200px;" id="counselor" name="counselor" @input="watchEvent($event, 'counselor')"> -->
                                            <input type="text" v-model="staffName" class="ml-05" style="width:200px;" id="counselor" name="counselor" @input="watchEvent($event, 'counselor')">
                                            <ul class="r" tabindex="0" v-bind:class="{ show: isAutoCounselor }" v-on:mouseover="removeValue">
                                                <li tabindex="-1" v-for="(el, index) in filterCounselorList" v-on:click="changeValue(el.name, 'counselor', el.idx, el.idx_crm_center, el.center_name)" v-on:keyup.enter="selectValue('enter', el.name, el.idx, el.idx_crm_center, el.center_name)" :key="index">
                                                    <span>{{ el.name }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                                <th>진행상황 <span class="red">*</span></th>
                                <td class="left pl-20">
                                    <select v-model="clientStatus" class="w-120px">
                                        <option value="">진행상황</option>
                                        <option value="진행">진행</option>
                                        <option value="종결">종결</option>
                                        <option value="홀딩">홀딩</option>
                                    </select>
                                </td>
                            </tr>
                            <!-- <tr>
                            <th>메모</th>
                            <td class="left" colspan="3">
                                <textarea type="text" v-model="memo" class="ml-20 w-98per h-100px" value="" />
                                </td>
                            </tr> -->
                        </tbody>
                    </table>

                    <div class="btns3 mgB80">
                        <a class="btn_sms mr-10 pointer" @click="cancel()">취소</a>
                        <a class="btn_cancle pointer" @click="putClientInfo()">수정</a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import {
    EmptyCheck
} from '@/utils/validation.js'
import {
    GetUrlParams    
} from '@/utils/common.js'
export default {
    data: () => ({
        companyList: [],
        centerList: [],
        idxCrmCenter: -1,
        name: '',
        gender: '',
        birth: '',
        email: '',
        idxCrmMetaSubject: -1,
        idxCrmStaff: -1,
        idxCrmMetaProduct: -1,
        consCount: 0,
        gubun: '',
        metaList: [],
        gubunList: [],
        typeList: [],
        subjectList: [],
        routeList: [],
        dangerList: [],
        proList: [],
        productList: [],
        idxCrmMetaType: -1,
        etcPrice: 0,
        idxCrmMetaRoute: -1,
        staffList: [],
        idxCrmMetaCompany: -1,
        staffName: '',
        companyName: '',
        isAutoCounselor: false, // 상담사 자동완성 on/off
        autoSearchCounselor: '', // 상담사 자동완성 검색
        isAutoCompany: false, // 소속(컴퍼니) 자동완성 on/off
        autoSearchCompany: '', // 소속(컴퍼니) 자동완성 검색
        clientStatus: '',
        eapYn: 'N',
        idx: -1,
        clientCode: '',
        memo: '',
        phone1: '010', // 휴대폰 앞자리
        phone2: '', // 휴대폰 가운데자리
        phone3: '', // 휴대폰 마지막자리
        idxCrmPerson: -1,
        ageRange:'', /* 연령선택 */
        personVO:{},
        auth: ''
    }),

    computed: {
        filterCounselorList() {
            const str = this.autoSearchCounselor
            const reg = /[^가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9|\s]/.test(str)
            if (reg === false && str !== '' && str !== ' ') {
                this.isAutoCounselor = true
                var filterNames = []
                filterNames = this.staffList.filter((el) => {
                    return el.name.match(str);
                });
                if (filterNames.length > 0) {
                    return filterNames
                } else {
                    this.isAutoCounselor = false
                }
            } else {
                this.isAutoCounselor = false
            }
        },

        filterCompanyList() {
            const str = this.autoSearchCompany
            const reg = /[^가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9|\s]/.test(str)
            if (reg === false && str !== '' && str !== ' ') {
                this.isAutoCompany = true
                var filterNames = []
                filterNames = this.companyList.filter((el) => {
                    return el.name.match(str);
                });
                if (filterNames.length > 0) {
                    return filterNames
                } else {
                    this.isAutoCompany = false
                }
            } else {
                this.isAutoCompany = false
            }
        }
    },

    mounted() {
        this.getCompanyListAll()
        this.getCenterListAll()
        this.getMetaList()
        this.getStaffList(localStorage.getItem("idxCrmCenter"),localStorage.getItem("auth"))
        var oParams = GetUrlParams()
        this.idx = oParams.idx
        this.getCustomerData(this.idx)
        this.getProductList()
        this.auth = localStorage.getItem("auth");
    },

    methods: {

        getPersonData(idx) {
            var params = {
                idx: idx
            }
            this.axios.get(`/api/v1/client/person/${idx}`, {
                })
                .then(res => {
                    if (res.data.err === 0) {

                        this.name = res.data.personVO.name
                        this.gender = res.data.personVO.gender
                        this.birth = res.data.personVO.birth
                        this.idxCrmMetaRoute = res.data.personVO.idxCrmMetaRoute
                        this.email = res.data.personVO.email
                        this.ageRange = res.data.personVO.ageRange;

                        this.personVO = res.data.personVO;
                        // 휴대폰 번호 매핑
                        if(res.data.personVO.phone !== null) {
                            var phone = res.data.personVO.phone.split('-')
                            if (phone.length === 3) {
                                this.phone1 = phone[0]
                                this.phone2 = phone[1]
                                this.phone3 = phone[2]
                            }
                        }
                    }
                })
        },

        getCustomerData(idx) {
            var params = {
                idx: idx
            }
            this.axios.get(`/api/v1/client/${idx}`, {
                })
                .then(res => {
                    if (res.data.err === 0) {
                        for (var item of Object.keys(res.data.clientVO)) {
                            if (typeof this[item] !== 'undefined') {

                                this[item] = res.data.clientVO[item]
                            } 
                        }
                        
                       if (res.data.clientVO.staffName !== '') {
                                this.staffName = res.data.clientVO.staffName
                                this.idxCrmStaff = res.data.clientVO['idxCrmStaff']
                        }
                        
                        if(res.data.clientVO.idxCrmMetaProduct !== '') {
                            this.getProductList(this.gubun);
                            this.idxCrmMetaProduct = res.data.clientVO.idxCrmMetaProduct
                        }

                        if(res.data.clientVO.idxCrmCompany !== '') {
                            this.idxCrmCompany = res.data.clientVO.idxCrmCompany
                        }
                        this.idxCrmCenter = res.data.clientVO.idxCrmCenter
                        this.getPersonData(res.data.clientVO.idxCrmPerson)
                    }
                })
        },

        checkCenter() {
            console.log(1234)

            if (this.idxCrmCenter === -1) {
                alert('상담센터를 먼저 선택해주세요.')
                this.staffName = ''
                return false;
            }
        },

        putClientInfo() {

            if (!EmptyCheck(this.name, '고객명을')) {
                return false
            }
            
            if(!this.gender){
                alert('성별을 선택해주세요');
                return false;
            }
            
            if (!EmptyCheck(this.ageRange, '연령을')) {
                return false
            }

            if (!EmptyCheck(this.phone1, '휴대폰번호를')) {
                return false
            }
            let flag = false

            // 상담사 자동완성 체크 부분
            if (this.staffName !== '' ) {
                for (var staff of this.staffList) {
                    if (staff.name === this.staffName && staff.idx === this.idxCrmStaff) {
                        flag = true
                    }
                }
            }

            // if (!flag) {
            //     alert('상담사를 다시 확인해주세요')
            //     return false
            // }

            if (this.idxCrmStaff === -1) {
                alert('존재하지 않는 상담사입니다.')
                return false
            }

            if (!EmptyCheck(this.clientStatus, '진행상황을')) {
                return false
            }

            /**
             * 내담자 정보 수정
             */
            this.putPersonInfo();
        },

        /**
         * 내담자 정보 수정
         */
        putPersonInfo(){
            this.personVO.ageRange = this.ageRange;
            
            var vo = new FormData();

            for(let irow in this.personVO) {
                if( this.personVO[irow] != null) {
                    vo.append(irow,this.personVO[irow]);
                }
            }
             // 각 파일 빈 binary로 추가해놓기
            vo.append('attachFile', new Blob())
            vo.append('attachFile2', new Blob())
            vo.append('attachFile3', new Blob())
            vo.append('attachFile4', new Blob())
            vo.append('attachFile5', new Blob())

            this.axios.post(`/api/v1/client/person/${this.personVO.idx}`, vo, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response)=>{
                const {result} = response.data;
                if(result === 'OK'){
                    /**
                     * 고객차트 정보수정
                     */
                    this.putChartInfo();
                }else{
                    alert(`내담자 정보 수정에 실패하였습니다.`);
                }
            }).catch(err => {
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert(`내담자 정보 수정에 실패하였습니다.`);
                }
            })
        },

        /**
         * 고객차트 관리 정보 수정
         */
        putChartInfo(){
            let phone='';
            // 핸드폰 번호를 전부 입력한 경우에만 핸드폰 포맷으로 파싱
            if (this.phone2 !== '' && this.phone3 !== '') {
                phone = this.phone1 + '-' + this.phone2 + '-' + this.phone3
            } else {
                phone = ''
            }
            if(this.staffName === null || this.staffName === ''){
                this.idxCrmStaff = null
            }
            var vo = {
                clientCode: this.clientCode,
                name: this.name,
                gender: this.gender,
                birth: this.birth,
                email: this.email,
                idxCrmCompany: this.idxCrmCompany,
                idxCrmMetaRoute: this.idxCrmMetaRoute,
                idxCrmCenter: this.idxCrmCenter,
                idxCrmStaff: this.idxCrmStaff,
                idxCrmMetaProduct: this.idxCrmMetaProduct,
                etcPrice: this.etcPrice,
                idxCrmMetaType: this.idxCrmMetaType,
                consCount: this.consCount,
                clientStatus: this.clientStatus,
                eapYn: this.eapYn,
                memo: this.memo,
                idxCrmPerson: this.idxCrmPerson,
                phone: phone,
                ageRange : this.ageRange,
            }
            /**
             * 내담자 정보 수정
             */
            this.axios.put(`/api/v1/client/${this.idx}`, vo)
                .then(res => {
                    if (res.data.err === 0) {
                        alert('고객정보 수정이 완료되었습니다.');
                        history.back()
                    } else {
                        alert(res.data.result)
                    }
                })
                .catch(err => {
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        getProductList() {

            var params = {
                value: this.gubun
            }
            this.axios.get('/api/v1/client/gubun', {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.gubunList = res.data.gubunList
                        this.productList = res.data.productList
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 유입경로, 상담구분, 상담유형 리스트
        getMetaList() {
            this.axios.get('/api/v1/client/meta', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.typeList = res.data.typeList
                        this.subjectList = res.data.subjectList
                        this.routeList = res.data.routeList
                        this.dangerList = res.data.dangerList
                        this.proList = res.data.proList
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
                
        },
        // 소속리스트(기관리스트)
        getCompanyListAll() {
            this.axios.get('/api/v1/company/all', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.companyList = res.data.companyList
                    } else {
                        //alert('소속리스트 결과가 없습니다.')
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        getCenterListAll() {

            this.axios.get('/api/v1/center/all', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.centerList = res.data.centerList
                    } else {
                        alert('센터리스트 결과가 없습니다.')
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        getStaffList(idxCrmCenter,auth) {

            var params = {
                idx: idxCrmCenter,
                loginAuth: auth
            }
            if(params.loginAuth === 'MASTER' || params.loginAuth === 'ADMIN'){
                idxCrmCenter = -1
            }
            this.axios.get(`/api/v1/center/staff/${idxCrmCenter}`, {
                    params: params
                })
                .then(res => {
                    console.log(params.idx)
            
                    if (res.data.err === 0) {
                        
                        this.staffName ='';
                        this.staffList = res.data.staffList
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 센터 자동완성 입력 감지
        watchEvent(e, type) {

            if (type === 'counselor') {

                this.autoSearchCounselor = e.target.value

            } else {
                this.autoSearchCompany = e.target.value
            }

        },

        // 자동완성 끄기
        removeValue() {
            if (document.querySelector('.r').classList.contains('key')) {
                document.querySelector('.r').classList.remove('key');
                document.querySelector('.r li.sel').classList.remove('sel');
            }
            if (document.querySelector('.c').classList.contains('key')) {
                document.querySelector('.c').classList.remove('key');
                document.querySelector('.c li.sel').classList.remove('sel');
            }
        },

        // 자동완성 선택한 경우 센터명 변경
        changeValue(str, type, idx, centerIdx, centerName) {
            if (type === 'counselor') {
                console.log('idx', idx)
                console.log('centerIdx', centerIdx)
                console.log('centerName', centerName)
                this.isAutoCounselor = false
                this.staffName = str
                this.idxCrmStaff = idx
            } else {
                console.log('idx', idx)
                this.isAutoCompany = false
                this.companyName = str
                this.idxCrmCompany = idx
            }
        },

        // 자동완성 켜기 및 선택
        selectValue(keycode, str, idx, centerIdx, centerName) {
            if (this.isAutoCounselor) {
                const hasClass = document.querySelector('.r').classList.contains('key');
                if (keycode === 'down') {
                    if (!hasClass) {
                        console.log('여기탐')
                        const thisEl = document.querySelectorAll('.r li')[0];
                        document.querySelector('.r').classList.add('key');
                        thisEl.classList.add('sel');
                        thisEl.focus();
                    } else {
                        const lastEl = document.querySelector('.r li:last-child');
                        const thisEl = document.querySelector('.r li.sel');
                        const nextEl = thisEl.nextElementSibling;
                        if (!lastEl.classList.contains('sel')) {
                            thisEl.classList.remove('sel');
                            nextEl.classList.add('sel');
                            nextEl.focus();
                        }
                    }
                }

                if (keycode === 'up' && hasClass) {
                    const firstEl = document.querySelectorAll('.r li')[0];
                    const thisEl = document.querySelector('.r li.sel');
                    const prevEl = thisEl.previousElementSibling;
                    if (!firstEl.classList.contains('sel')) {
                        thisEl.classList.remove('sel');
                        prevEl.classList.add('sel');
                        prevEl.focus();
                    } else {
                        document.querySelector('.s').focus();
                    }
                }

                if (keycode === 'enter' && hasClass) {
                    this.changeValue(str, 'counselor', idx, centerIdx, centerName);
                }
            }

            if (this.isAutoCompany) {
                const hasClass = document.querySelector('.c').classList.contains('key');
                if (keycode === 'down') {
                    if (!hasClass) {
                        const thisEl = document.querySelectorAll('.c li')[0];
                        document.querySelector('.c').classList.add('key');
                        thisEl.classList.add('sel');
                        thisEl.focus();
                    } else {
                        const lastEl = document.querySelector('.c li:last-child');
                        const thisEl = document.querySelector('.c li.sel');
                        const nextEl = thisEl.nextElementSibling;
                        if (!lastEl.classList.contains('sel')) {
                            thisEl.classList.remove('sel');
                            nextEl.classList.add('sel');
                            nextEl.focus();
                        }
                    }
                }

                if (keycode === 'up' && hasClass) {
                    const firstEl = document.querySelectorAll('.c li')[0];
                    const thisEl = document.querySelector('.c li.sel');
                    const prevEl = thisEl.previousElementSibling;
                    if (!firstEl.classList.contains('sel')) {
                        thisEl.classList.remove('sel');
                        prevEl.classList.add('sel');
                        prevEl.focus();
                    } else {
                        document.querySelector('.s').focus();
                    }
                }

                if (keycode === 'enter' && hasClass) {
                    this.changeValue(str, 'company', idx);
                }
            }
        },

        // 상담예약관리 페이지로 돌아가기
        cancel() {
            //this.$router.push('/origin/customer_manage')
            history.back()
        },

        // 고객등록 제출
        submit() {

        }
    }
}
</script>

<style lang="scss">
#app {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    .search {
        position: relative;
        margin: 0 auto;
        width: 100%;
        max-width: 600px;

        .s {
            float: left;
            margin-left: 15px;
            width: 255px;
            height: 30px;
            object-fit: contain;
            border-radius: 3px;
            border: solid 1px #cccccc;
            background-color: #ffffff;
            padding-left: 15px;

            &::placeholder {
                font-size: 14px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 4.29;
                letter-spacing: normal;
                text-align: left;
                color: #cccccc;
            }
        }

        .c {
            display: none;
            position: absolute;
            left: 0;
            top: 28px;
            width: 100%;
            height: 156px;
            overflow-y: auto;
            list-style: none;
            padding-left: 20px;

            &.show {
                display: block;
                z-index: 10;
            }

            li {
                margin-top: -1px;
                padding: 0 20px;
                width: 91%;
                height: 40px;
                background-color: #fff;
                box-sizing: border-box;
                border: 1px solid #dcdcdc;
                outline: none;
                font-size: 16px;
                line-height: 40px;
                cursor: pointer;

                &:hover,
                &.sel {
                    background-color: darken(#fff, 5%);
                }

                &:last-child {
                    border-radius: 0px 0px 4px 4px;
                }
            }
        }

        .r {
            display: none;
            position: absolute;
            left: 0;
            top: 28px;
            width: 100%;
            height: 156px;
            overflow-y: auto;
            list-style: none;
            padding-left: 20px;

            &.show {
                display: block;
                z-index: 10;
            }

            li {
                margin-top: -1px;
                padding: 0 20px;
                width: 91%;
                height: 40px;
                background-color: #fff;
                box-sizing: border-box;
                border: 1px solid #dcdcdc;
                outline: none;
                font-size: 16px;
                line-height: 40px;
                cursor: pointer;

                &:hover,
                &.sel {
                    background-color: darken(#fff, 5%);
                }

                &:last-child {
                    border-radius: 0px 0px 4px 4px;
                }
            }
        }

        p {
            padding: 10px 0;
            text-align: right;
            font-size: 12px;
        }
    }
}
</style>
